import React, { useEffect, useState } from "react";
import {getOrders} from "../../methods/subcompaniesMethods";
import SelectOrder from "./SelectOrder";
import PermissionRequest from "../Modules/PermissionRequest";
import MessageJSX from "../Modules/MessageJSX";
import {deleteOrder} from "../../methods/editOrder";

export default function Orders({ token }) {

    const [orders, setOrders] = useState(null);
    const [selectOrder, setSelectOrder] = useState(null);
    const [confirmed, setConfirmed] = useState(null);
    const [success, setSuccess] = useState(null);

    const subtitle = selectOrder && `Предписание №${selectOrder?.id} "${selectOrder?.status}", по тендеру ${selectOrder?.tender_id}, Категория ${selectOrder?.category}`;
    const [answer, setAnswer] = useState({});

    const confirmHandler = async (data) => {

        // console.log('\n confirmHandler', data);
        setConfirmed(data);

        if (!data) {
            setSelectOrder(null);
        }
    }

    useEffect(() => {
        const fetchOrders = async () => {
            const fetchedOrders = await getOrders(token);
            // console.log('\n fetchedOrders', fetchedOrders);
            setOrders(fetchedOrders);
        };

        fetchOrders();
    }, [token]);
    useEffect(() => {

        // console.log('\n ', token,confirmed,selectOrder);

        const deleteOrderHandler = async () => {

            const response = selectOrder && await deleteOrder(token, selectOrder);

            console.log('\n deleteOrderHandler', response);

            if (response.errorMessage) {

                setSuccess("error");
                setAnswer({
                    message: response.errorMessage,
                    label: `Ошибка удаления ${subtitle}`
                });
            }
            else if (response) {
                setSuccess(response);
                setAnswer({
                    message: `Успешно удалено ${subtitle}`,
                });
            }
        }

        if (token && confirmed && selectOrder) deleteOrderHandler();
    },[token, confirmed, selectOrder, subtitle]);

    // if (orders) console.log('\n orders', orders);
    if (selectOrder) console.log('\n selectOrder', selectOrder);

    if (orders) return (
        !selectOrder ?
            <SelectOrder
                orders={orders}
                setSelectOrder={setSelectOrder}
            /> :
            !confirmed ?
                <PermissionRequest
                    label={"Подвердите удаление"}
                    subtitle={subtitle}
                    permission={confirmHandler}
                /> :
                success && <MessageJSX
                    message={answer.message}
                    answer={answer.label}
                    correct={success === true}
                    error={success === "error"}
                    button={{
                        label: "Назад",
                        onClick: () => {window.location.reload()}
                    }}
                />
    );
}
