import React from "react";
import {List, ListItem, ListItemText} from "@mui/material";

export const ErrorOccurred = (
    response,
    label,
    functionName,
) => {

    const errors = response?.data?.errors;

    console.log(
        `\n response`, response,
        `\n label`, label,
        `\n functionName`, functionName,
        `\n errors`, errors,
    );

    const returnJsx = (
        <React.Fragment>
            {(errors && errors.length > 0) ?
                <List>
                    {errors.map((e,id) => {

                        // console.log(`\n e`, e);

                        return (
                            <ListItem key={id}>
                                <ListItemText
                                    primary={e.detail ? e.detail : `Статус: ${e}`}
                                    secondary={e.attr || e.code}
                                />
                            </ListItem>
                        );
                    })}
                </List> :
                `Статус: ${response?.status}`
            }
        </React.Fragment>
    );

    console.log(`\n error ${functionName}`, errors || response);

    const returnErrors = {};
    errors?.forEach(e => {

        returnErrors[e.attr] = e.detail;
    });
    // console.log(`\n returnErrors`, returnErrors);

    return ({
        jsx: returnJsx,
        errors: Object.keys(returnErrors).length > 0 && returnErrors
    });
}