import {getAllProjects} from "./getCompanies";
import axios from "axios";
import {api, headersRequests} from "./Settings";
import {ErrorOccurred} from "./ErrorOccurred";
import {sendRequestsSequentially} from "./webWorker";

export const getFinancialPlan = async (token) => {

    try {
        const getData = await getAllProjects(token);
        // console.log('\n getData', getData);
        const requests = getData.projects.map(async (project) => {

            const company_id = project.company_id;
            const project_id = project.id;

            try {
                const response = await axios.get(
                    `${api}/finplan/companies/${company_id}/projects/${project_id}/`,
                    headersRequests('get', token)
                );

                const financial_plan = Object.keys(response.data?.financial_plan?.json).map(id => ({
                    id: `${company_id}_${project_id}_${id}`,
                    ...response.data?.financial_plan?.json[id],
                    company_id,
                    project_id
                }));

                return {
                    company_id,
                    project_id,
                    financial_plan
                };
            }
            catch (error) {
                return {
                    company_id,
                    project_id,
                    errors: error.response?.data?.errors
                };
            }

        });
        const results = await Promise.allSettled(requests);
        const financialPlans = results
            .filter(plan => plan.value.financial_plan)
            .map(plan => plan.value)
        ;
        // console.log('\n financialPlans', financialPlans);
        getData.financial_plan = financialPlans;
        return getData;
    }
    catch (errors) {
        ErrorOccurred(errors.response, 'Ошибка получения всех финансовых планов компаний', 'getFinancialPlan');
    }
    return [];
};

export const resetFinancialPlan = async (
    token,
    subcompaniesByTenders,
    company_id,
    project_id,
    financialPlan
) => {

    try {

        // console.log('\n resetFinancialPlan', subcompaniesByTenders);
        const resetSubcompaniesSsr = subcompaniesByTenders.map(subcompany => {

            const company_id = subcompany.company_id;
            const project_id = subcompany.project_id;
            const tender_id = subcompany.tender;
            const subcompany_id = subcompany.subcompany;

            return ({
                method: 'post',
                url: `${api}/work-journal/companies/${company_id}/projects/${project_id}/tenders/${tender_id}/subcompanies/${subcompany_id}/reset-financial-plan-by-super-admin/ssr/`,
                headers: headersRequests('post_form', token).headers,
            });
        });

        sendRequestsSequentially(resetSubcompaniesSsr).then(results => {
            console.log(results);
        });

        const getAllWorkJournals = await axios.get(
            `${api}/work-journal/companies/${company_id}/projects/${project_id}/all-work-journals`,
            headersRequests('get', token)
        );
        const usedSsr = [...new Set(getAllWorkJournals.data.flatMap(j => j.estimate).filter(e => e.ssr).map(e => e.ssr))];
        const usedSrrFinancialPlan = financialPlan.filter(f => usedSsr.includes(f.ssr));
        const json = {};
        usedSrrFinancialPlan.forEach(f => {

            if (!json[f.ssr]) json[f.ssr] = {
                "ssr": f.ssr,
                "name": f.name,
                "type": f.type,
                "periods": f.periods,
            };
        })
        // console.log('\n usedSsr', usedSsr, json);

        const updateByProject = [
            {
                method: 'put',
                url: `${api}/finplan/companies/${company_id}/projects/${project_id}/update-by-project/`,
                data: {json},
                headers: headersRequests('post_json', token).headers,
            }
        ];
        sendRequestsSequentially(updateByProject).then(results => {
            console.log(results);
            return (results);
        });
    }
    catch (errors) {
        ErrorOccurred(errors.response, 'Ошибка получения данных тендеров компаний', 'getCompanyTenders');
    }
    return [];
};