import React, {useEffect, useState} from "react";
import {getFinancialPlan, resetFinancialPlan} from "../../methods/financialPlan";
import {getCompanyTenders, getSubcomanies} from "../../methods/getCompanies";

import SelectFinancialPlan from "./SelectFinancialPlan";
import PermissionRequest from "../Modules/PermissionRequest";
import MessageJSX from "../Modules/MessageJSX";

export default function ReFinancePlan({token}) {

    const [financialPlans, setFinancialPlans] = useState(null);
    const [selectedFinancialPlan, setSelectedFinancialPlan] = useState("");
    const [requestTitle,setRequestTitle] = useState(null);
    const [permissionUser, setPermissionUser] = useState(false);
    const [isSelectedFinancialPlanReset, setIsSelectedFinancialPlanReset] = useState(false);

    const permissionHandler = (request) => {

        console.log('\n permissionHandler', request);
        if (request) setPermissionUser(true);
        else setSelectedFinancialPlan("");
    }

    useEffect(() => {

        const getData = async () => {

            const updateFinancialPlans = await getFinancialPlan(token);
            // console.log('\n updateFinancialPlans', updateFinancialPlans);
            setFinancialPlans(updateFinancialPlans);
        }

        getData();
    }, [token]);
    useEffect(() => {

        if (selectedFinancialPlan !== "") {

            const company_id = +selectedFinancialPlan.split('-')[0];
            const project_id = +selectedFinancialPlan.split('-')[1];
            const project = financialPlans?.projects?.find(p => p.id === project_id)?.name;
            const company = financialPlans?.companies?.find(p => p.id === company_id)?.name;

            setRequestTitle(`проект ${project} (id ${project_id}) компаниии ${company} (id ${company_id})`);

            // console.log(
            //     '\n company_id', company_id,
            //     '\n project_id', project_id,
            // );

            const getData = async () => {

                const updateTenders = await getCompanyTenders(token,company_id, project_id);
                // console.log('\n updateTenders', updateTenders);

                if (updateTenders?.length > 0) {

                    const updateSubcompanies = await getSubcomanies(token,company_id);
                    // console.log('\n updateSubcompanies', updateSubcompanies);
                    const tendersId = updateTenders.map(tender => tender.id);
                    // console.log('\n tendersId', tendersId);
                    const subcompaniesByTenders = updateSubcompanies
                        .filter(subcompany => tendersId.includes(subcompany.tender))
                        .map(subcompany => ({
                            ...subcompany,
                            company_id,
                            project_id,
                        }))
                    ;
                    // console.log('\n subcompaniesByTenders', subcompaniesByTenders);

                    const currentFinancialPlan = financialPlans.financial_plan.find(f => f.company_id === company_id && f.project_id === project_id).financial_plan;
                    // console.log('\n currentFinancialPlan', currentFinancialPlan);

                    const resetFinancialPlanSend = await resetFinancialPlan(token,subcompaniesByTenders,company_id,project_id, currentFinancialPlan);
                    console.log('\n resetFinancialPlanSend', resetFinancialPlanSend);
                    setIsSelectedFinancialPlanReset(resetFinancialPlanSend)
                }
            }

            if (permissionUser) getData();
        }
    },[financialPlans, selectedFinancialPlan, token, permissionUser]);

    // if (financialPlans) console.log('\n financialPlans', financialPlans);
    // if (selectedFinancialPlan) console.log('\n selectedFinancialPlan', selectedFinancialPlan);

    return (
        <React.Fragment>
            {selectedFinancialPlan === "" ?
                <SelectFinancialPlan
                    label={"Сброс финансового плана"}
                    subtitle={"Выбирите компанию и проект для сброса финансового плана"}
                    financialPlans={financialPlans}
                    selectedFinPlan={selectedFinancialPlan}
                    setSelectedFinPlan={setSelectedFinancialPlan}
                /> :
                isSelectedFinancialPlanReset ?
                    <MessageJSX
                        message={(<span>Сброс финансового плана прошел успешно <span style={{display:"block"}}>{requestTitle}</span></span>)}
                        correct
                        button={{
                            label: "Назад",
                            onClick: () => {
                                setSelectedFinancialPlan("");
                                setPermissionUser(false);
                                setIsSelectedFinancialPlanReset(false);
                            }
                        }}
                    />
                    :
                    <PermissionRequest
                        label={"Подвердите сброс финансового плана"}
                        subtitle={requestTitle}
                        permission={permissionHandler}
                    />
            }
        </React.Fragment>
    );
}
