import {UserCheck} from "./getUser";
import axios from "axios";
import {api, headersRequests} from "./Settings";
import {ErrorOccurred} from "./ErrorOccurred";
import {getSections} from "./getCompanies";
import statuses from "../UI/global/statuses.json";

export const getSubcompanies = async (token) => {
    try {
        const getUser = await UserCheck(token);
        if (getUser && Object.keys(getUser).length > 0) {
            const response = await axios.get(`${api}/subcompanies/`, headersRequests('get', token));
            const subcompanies = response?.data?.map(company => {

                return ({
                    ...company,
                });
            });
            // console.log('\n subcompanies', subcompanies);
            return subcompanies;
        }
    }
    catch (errors) {
        ErrorOccurred(errors.response, 'Ошибка получения данных подрядчиков', 'subcompaniesMethods');
    }
    return [];
};

export const getSubcompaniesTenders = async (token) => {
    try {
        
        const subcompanies = await getSubcompanies(token);
        
        // console.log('\n subcompanies', subcompanies);

        const getLots = subcompanies.map(async (subcompany) => {

            const response = await axios.get(`${api}/subcompanies/${subcompany.id}/won-tender-lots`, headersRequests('get', token));
            const result = response.data.map(lot => ({...lot, subcompany_info: subcompany}));
            
            // console.log('\n result', result);

            return (result);
        });
        const getAllLots = (await Promise.all(getLots)).flat();
        const tenders = getAllLots.map(lot => {

            const name = lot?.tender?.name;
            const subcompany_name = lot.subcompany_info.name;
            const project_name = lot?.tender?.project?.name;
            const project_id = lot?.tender?.project?.id;
            const company_name = lot?.tender?.project?.company?.name;
            const company_id = lot?.tender?.project?.company?.id;

            // console.log('\n lot', lot);

            return ({
                ...lot,
                name,
                subcompany_name,
                project_name,
                project_id,
                company_name,
                company_id,
            })
        });
        // console.log('\n tenders', tenders);

        return tenders;
    }
    catch (errors) {
        ErrorOccurred(errors.response, 'Ошибка получения данных подрядчиков', 'getSubcompaniesTenders');
    }
    return [];
};

export const getOrders = async (token) => {
    try {

        const tenders = await getSubcompaniesTenders(token);
        // console.log('\n tenders', tenders);
        const projects = tenders.map(t => ({
            project_id: t.tender.project.id,
            company_id: t.tender.project.company.id
        }));
        // console.log('\n projects', projects);
        const uniqueProjects = Array.from(new Set(projects.map(JSON.stringify))).map(JSON.parse);
        // console.log('\n uniqueProjects', uniqueProjects);
        const sections = uniqueProjects.map(async (p) => {
            const get = await getSections(token, p.company_id, p.project_id);
            return get;
        });
        const allSections = (await Promise.all(sections)).flat(2);
        const allSectionsTenders = allSections.flatMap(s => {
            
            const tenderList = tenders
                .filter(tender => (tender.company_id === s.company_id && tender.project_id === s.project_id))
                .map(tender => ({
                    tender_id: tender.id,
                    building_id: s.building,
                    company_id: s.company_id,
                    section_id: s.id,
                    project_id: s.project_id
                }))
            ;

            return tenderList;
        });
        // console.log('\n allSectionsTenders', allSectionsTenders);
        const orders = allSectionsTenders.map(async (s) => {

            const { company_id, project_id, tender_id, building_id, section_id } = s;

            const response = await axios.get(
                `${api}/company/${company_id}/projects/${project_id}/tenders/${tender_id}/buildings/${building_id}/sections/${section_id}/orders/`,
                headersRequests('get', token)
            );

            const orders = response?.data?.data.length > 0 && response?.data?.data?.map(r => ({
                ...r,
                company_id,
                project_id,
                tender_id,
                building_id,
                section_id,
            }))
            return orders;
        });
        const allOrders = (await Promise.all(orders))
            .flat()
            .filter(order => order)
            .sort((a, b) => a.id - b.id)
            .map(order => {

                const history_steps = order.history?.length;
                const status = statuses["orders"][order.history[order.history?.length -1].status];

                return ({
                    ...order,
                    history_steps,
                    status
                })
            })
        ;
        // console.log('\n allOrders', allOrders);
        return allOrders;
    }
    catch (errors) {
        ErrorOccurred(errors.response, 'Ошибка получения предписаний', 'getOrders');
    }
    return [];
};

export const getAllDocuments = async (token) => {
    try {

        const tenders = await getSubcompaniesTenders(token);

        if (tenders?.length > 0) {

            // console.log('\n tenders', tenders);

            const documents = tenders.map(async (tender) => {

                const { company_id, project_id } = tender;

                const subcompany_id = tender.subcompany;
                const tender_id = tender.tender.id;

                const response = await axios.get(
                    `${api}/subcompanies/${subcompany_id}/companies/${company_id}/projects/${project_id}/tenders/${tender_id}/all-documents`,
                    headersRequests('get', token)
                );
                const result = response.data.data;
                // console.log('\n result', result);

                return ({
                    ...result,
                    tender,
                    tender_name: tender.tender.name,
                    tender_company_name: tender.company_name,
                    tender_subcompany_name: tender.subcompany_info.name,
                    id: tender.tender.id,
                    quality_documents_length: result.quality_documents?.length,
                    register_acts_length: result.register_acts?.length,
                    register_documents_length: result.register_documents?.length,
                    work_payment_certificates_length: result.work_payment_certificates?.length,
                });
            });
            const allDocuments = (await Promise.all(documents)).flat();
            // console.log('\n allDocuments', allDocuments);
            return allDocuments;
        }
    }
    catch (errors) {
        ErrorOccurred(errors.response, 'Ошибка получения документов', 'getAllDocuments');
    }
    return null;
};