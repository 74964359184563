import axios from 'axios';
import {ErrorOccurred} from "./ErrorOccurred";
import {api, headersRequests} from "./Settings";

export const GetUser = async (data) => {
    // console.log('\n GetUser api', api);
    // console.log('\n GetUser headers', headers('post_auth'));

    try {
        const response = await axios.post(
            `${api}/auth/login/`,
            data,
            headersRequests('post_auth')
        );

        // console.log('\n GetUser response', response.data);

        return response.data;
    }
    catch (errors) {
        return (
            ErrorOccurred(
                errors.response,
                'Ошибка авторизации пользователя',
                'GetUser'
            )
        );
    }
}

export const UserCheck = async (token) => {

    try {

        // console.log('\n UserCheck token', token);

        const response = await axios.get(
            `${api}/user/`,
            headersRequests('get', token)
        );

        // console.log('\n GetUser response is_superuser', response?.data);

        return response?.data;
    }
    catch (errors) {

        return (
            ErrorOccurred(
                errors.response,
                'Ошибка проверки пользователя',
                'UserCheck'
            )
        );
    }
}