import { Box, Button, TextField } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../Header";

const generateInitialValues = (fields) => {
    const initialValues = {};
    fields?.forEach(field => {
        initialValues[field.name] = "";
    });
    return initialValues;
};

const generateValidationSchema = (fields) => {
    const schema = {};
    fields?.forEach(field => {
        if (field.type === 'email') {
            schema[field.name] = yup.string().email("Не верный email").required("required");
        } else if (field.type === 'text') {
            schema[field.name] = yup.string().required("required");
        } else if (field.type === 'phone') {
            schema[field.name] = yup.string().matches(phoneRegExp, "Не верный телефон").required("required");
        } else if (field.type === 'password') {
            schema[field.name] = yup.string().min(8, "Пароль должен быть более 8-ми символов").required("required");
        }
    });
    return yup.object().shape(schema);
};

const phoneRegExp =
    /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

const Form = ({
                  title,
                  subtitle,
                  fields,
                  submitButton,
                  onSubmit,
                  errors,
                  correct,
                  warning,
}) => {
    const isNonMobile = useMediaQuery("(min-width:600px)");

    const initialValues = generateInitialValues(fields);
    const validationSchema = generateValidationSchema(fields);

    const handleFormSubmit = (values) => {
        // console.log(values);
        onSubmit(values);
    };

    // if (initialValues) console.log(`initialValues`,initialValues);
    // if (isNonMobile) console.log(`isNonMobile`,isNonMobile);

    return (
        <Box m="20px">
            <Header
                title={title}
                subtitle={subtitle}
                correct={correct}
                error={errors}
                warning={warning}
            />

            <Formik
                onSubmit={handleFormSubmit}
                initialValues={initialValues}
                validationSchema={validationSchema}
            >
                {({
                      values,
                      errors,
                      touched,
                      handleBlur,
                      handleChange,
                      handleSubmit,
                  }) => (
                    <form onSubmit={handleSubmit}>
                        <Box
                            display="grid"
                            gap="30px"
                            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                            sx={{
                                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                            }}
                        >
                            {fields?.map((field, index) => (
                                <TextField
                                    key={index}
                                    fullWidth
                                    variant="filled"
                                    onBlur={handleBlur}
                                    onChange={handleChange}

                                    label={field.label}
                                    name={field.name}
                                    type={field.type}
                                    sx={{ gridColumn: field.span && `span ${field.span}` }}

                                    value={values[field.name]}
                                    error={!!touched[field.name] && !!errors[field.name]}
                                    helperText={touched[field.name] && errors[field.name]}
                                />
                            ))}
                        </Box>
                        <Box display="flex" justifyContent="start" mt="20px">
                            <Button type="submit" color="info" variant="contained">
                                {submitButton}
                            </Button>
                        </Box>
                    </form>
                )}
            </Formik>
        </Box>
    );
};

export default Form;
