import SelectCompany from "./SelectSubcompany";
import {useEffect, useState} from "react";
import {getSubcompaniesTenders} from "../../methods/subcompaniesMethods";
import React from "react";
import PermissionRequest from "../Modules/PermissionRequest";
import {resetEstimatePeriod} from "../../methods/financialPeriod";
import MessageJSX from "../Modules/MessageJSX";

export default function ResetEstimatePeriod({ token }) {

    const [tenders, setTenders] = useState(null);
    const [selectTender,setSelectTender] = useState(null);
    const [confirmed, setConfirmed] = useState(null);
    const [success, setSuccess] = useState(true);

    const currentTender = tenders?.find(tender => tender.id === selectTender);
    const subtitle = selectTender && `Тендер ${currentTender?.name}, Подрядчик ${currentTender.subcompany_info.name}, Компания ${currentTender.tender.project.company.name}, Проект ${currentTender.tender.project.name}`

    // if (selectTender) console.log('\n selectTender', selectTender);

    const confirmHandler = async (data) => {

        // console.log('\n confirmHandler', data);
        setConfirmed(data);

        if (!data) {
            setSelectTender(null);
        }
    }

    useEffect(() => {

        const getTenders = async () => {

            const updateTenders = await getSubcompaniesTenders(token);

            console.log('\n updateTenders', updateTenders);
            setTenders(updateTenders);
        };

        getTenders();
    }, [token]);
    useEffect(() => {

        const resetEstimatePeriodHandler = async () => {

            const updateResetEstimatePeriod = await resetEstimatePeriod(token, currentTender);
            // console.log('\n updateResetEstimatePeriod', updateResetEstimatePeriod);
            setSuccess(updateResetEstimatePeriod);
        };

        if (confirmed) resetEstimatePeriodHandler();
    }, [confirmed, token, currentTender]);

    return (tenders ?
            !selectTender &&
                <SelectCompany
                    tenders={tenders}
                    setSelectTender={setSelectTender}
                /> :
                !confirmed ?
                    <PermissionRequest
                        label={"Подвердите сброс финансового периода"}
                        subtitle={subtitle}
                        permission={confirmHandler}
                    /> :
                    success && <MessageJSX
                        message={(<span>Сброс финансового периода прошел успешно <span style={{display:"block"}}>{subtitle}</span></span>)}
                        correct
                        button={{
                            label: "Назад",
                            onClick: () => {
                                setSelectTender(null);
                                setConfirmed(null);
                                setSuccess(null);
                            }
                        }}
                    />
        );
}