import {Box} from "@mui/material";
// import {tokens} from "../../../theme";
import Header from "../../Header";
import React from "react";

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

const SelectFinancialPlan = ({
                                 label,
                                 subtitle,
                                 financialPlans,
                                 selectedFinPlan,
                                 setSelectedFinPlan,
                   }) => {

    // const theme = useTheme();
    // const colors = tokens(theme.palette.mode);

    const handleProject = (event) => {
        setSelectedFinPlan(event.target.value);
    };

    return (
        <Box m="20px">
            <Header
                title={label}
                subtitle={subtitle}
            />
            <FormControl sx={{ m: 1, minWidth: 220 }} size="small">
                <InputLabel id="demo-select-small-label">Выбрать проект</InputLabel>
                <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    value={selectedFinPlan}
                    onChange={handleProject}
                >
                    {financialPlans?.financial_plan?.map(fp => {

                        const id = `${fp.company_id}-${fp.project_id}`;
                        const name= `${financialPlans.companies.find(c => c.id === fp.company_id).name} - ${financialPlans.projects.find(c => c.id === fp.project_id).name}`;
                        const idsInfo= `(company_id: ${fp.company_id}) - (project_id: ${fp.project_id})`;
                        
                        return (
                            <MenuItem
                                key={id}
                                value={id}
                            >
                                {name}
                                <br />
                                {idsInfo}
                            </MenuItem>
                        );
                    })}
                </Select>
            </FormControl>
        </Box>
    );
};

export default SelectFinancialPlan;