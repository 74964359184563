import {ErrorOccurred} from "./ErrorOccurred";
import axios from "axios";
import {api, headersRequests} from "./Settings";

export const deleteOrder = async (token, selectOrder) => {
    try {
        const { company_id, project_id, tender_id, building_id, section_id, id } = selectOrder;
        const headers = headersRequests('post', token);

        const response = await axios.delete(
            `${api}/company/${company_id}/projects/${project_id}/tenders/${tender_id}/buildings/${building_id}/sections/${section_id}/orders/${id}/delete-by-super-admin/`,
            headers
        );

        return (response.status === 204);
    } catch (errors) {
        const errorMessage = ErrorOccurred(errors.response, 'Ошибка удаления предписания', 'deleteOrder').jsx;
        return ({
            errorMessage,
            status: errors.response.status,
        });
    }
};

