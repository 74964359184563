import { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import { useCookies } from "react-cookie";
import { UserCheck } from "./components/methods/getUser";

import Topbar from "./scenes/global/Topbar";
import Sidebar from "./components/UI/global/Sidebar";
import Dashboard from "./scenes/dashboard";
import Team from "./scenes/team";
import Invoices from "./scenes/invoices";
import Contacts from "./scenes/contacts";
import Bar from "./scenes/bar";
import Form from "./scenes/form";
import Line from "./scenes/line";
import Pie from "./scenes/pie";
import FAQ from "./scenes/faq";
import Geography from "./scenes/geography";
import Authorization from "./components/Public/Authorization";
import Calendar from "./scenes/calendar/calendar";

import Companies from "./components/Work/Company/Companies";
import AllUsers from "./components/Work/Company/AllUsers";
import AllProjects from "./components/Work/Company/AllProjects";
import ReFinancePlan from "./components/Work/Company/ResetFinancePlan";
import ResetEstimatePeriod from "./components/Work/Subcompany/ResetEstimatePeriod";
import Orders from "./components/Work/Subcompany/Orders";
import SubcompaniesDocuments from "./components/Work/Subcompany/SubcompaniesDocuments";
import SubcompaniesEditDocuments from "./components/Work/Subcompany/SubcompaniesEditDocuments";
import Tariffs from "./components/Work/Company/Tariffs";
import EditTariffs from "./components/Work/Company/EditTariffs";

function App() {
    const [cookie] = useCookies(['access']);
    const [token, setToken] = useState(Object.keys(cookie).length > 0 && cookie?.access);
    const [theme, colorMode] = useMode();
    const [isSidebar, setIsSidebar] = useState(true);
    const [isUserAllowed, setIsUserAllowed] = useState(null);
    const currentPath = process.env.PUBLIC_URL;

    useEffect(() => {
        const getData = async () => {
            const getUser = await UserCheck(token);
            if ((getUser && Object.keys(getUser).length > 0) && getUser?.is_superuser) {
                setIsUserAllowed(getUser);
            }
        }

        if (token) getData();
    }, [token]);

    return (
        <ColorModeContext.Provider value={colorMode}>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <div className="app">
                    {isUserAllowed && <Sidebar isSidebar={isSidebar} user={isUserAllowed} />}
                    <main className="content">
                        {isUserAllowed && <Topbar setIsSidebar={setIsSidebar} />}
                        <Routes>
                            <Route path={currentPath} element={<Authorization setTokenAuth={setToken} />} />

                            {/* Company */}
                            <Route path={`${currentPath}/welcome`} element={<Companies token={token} />} />
                            <Route path={`${currentPath}/all-users`} element={<AllUsers token={token} />} />
                            <Route path={`${currentPath}/all-projects`} element={<AllProjects token={token} />} />
                            <Route path={`${currentPath}/remove-finance-plan`} element={<ReFinancePlan token={token} />} />
                            <Route path={`${currentPath}/tariffs`} element={<Tariffs token={token} />} />
                            <Route path={`${currentPath}/tariffs/edit/*`} element={<EditTariffs token={token} />} />

                            {/* subcompany */}
                            <Route path={`${currentPath}/reset-estimate-period`} element={<ResetEstimatePeriod token={token} />} />
                            <Route path={`${currentPath}/orders`} element={<Orders token={token} />} />
                            <Route path={`${currentPath}/subcompanies-documents`} element={<SubcompaniesDocuments token={token} />} />
                            <Route path={`${currentPath}/subcompanies-documents/edit-documents/`} element={<SubcompaniesEditDocuments token={token} />} />

                            {/*tests*/}
                            <Route path={`${currentPath}/dashboard`} element={<Dashboard />} />
                            <Route path={`${currentPath}/team`} element={<Team />} />
                            <Route path={`${currentPath}/contacts`} element={<Contacts />} />
                            <Route path={`${currentPath}/invoices`} element={<Invoices />} />
                            <Route path={`${currentPath}/form`} element={<Form />} />
                            <Route path={`${currentPath}/bar`} element={<Bar />} />
                            <Route path={`${currentPath}/pie`} element={<Pie />} />
                            <Route path={`${currentPath}/line`} element={<Line />} />
                            <Route path={`${currentPath}/faq`} element={<FAQ />} />
                            <Route path={`${currentPath}/calendar`} element={<Calendar />} />
                            <Route path={`${currentPath}/geography`} element={<Geography />} />
                        </Routes>
                    </main>
                </div>
            </ThemeProvider>
        </ColorModeContext.Provider>
    );
}

export default App;