import axios from "axios";

export const sendRequestsSequentially = async (requests) => {

    console.log('\n sendRequestsSequentially', requests);

    const results = [];

    for (let request of requests) {
        try {
            const response = await axios(request);
            results.push({ success: true, data: response.data });
        } catch (error) {
            results.push({ success: false, error: error.message });
        }
    }

    return results;
}
