import axios from 'axios';
import { ErrorOccurred } from './ErrorOccurred';
import { api, headersRequests } from './Settings';
import { UserCheck } from './getUser';

export const getAllCompanies = async (token) => {
    try {
        const getUser = await UserCheck(token);
        if (getUser && Object.keys(getUser).length > 0) {
            const response = await axios.get(`${api}/company/`, headersRequests('get', token));
            const companies = response?.data?.map(company => {

                company.subcompany_label = company.subcompany ? "Подрядчик" : "Инвестор";
                company.type_label = company.type === 1 ?
                    "Физ. лицо" :
                    company.type === 2 ?
                        "Юр. лицо" :
                        "ИП"
                ;
                company.is_paid_status = company.is_paid ? "Оплачен" : "Не оплачен";

                return ({
                   ...company,
                    company_label: company.name,
                    company_id: company.id
                });
            });
            return companies;
        }
    }
    catch (errors) {
        ErrorOccurred(errors.response, 'Ошибка получения данных компаний', 'getAllCompanies');
    }
    return [];
};

export const getAllUsers = async (token) => {
    try {
        const companies = await getAllCompanies(token);
        if (companies.length > 0) {
            const requests = companies.map(async (company) => {
                const company_id = company.id;
                const response = await axios.get(`${api}/company/${company_id}/users`, headersRequests('get', token));
                return response?.data?.users?.map(user => ({ ...user, company_id })) || [];
            });

            const allUsers = (await Promise.all(requests)).flat();
            const uniqueUsersId = [...new Set(allUsers.map(user => user.id))].map(id => allUsers.find(user => user.id === id));
            // console.log('\n uniqueUsersId', uniqueUsersId);
            const users = uniqueUsersId.sort((a, b) => a.company_id - b.company_id);
            // console.log('Sorted users:', users);

            return ({
                users,
                companies
            });
        }
    }
    catch (errors) {
        ErrorOccurred(errors.response, 'Ошибка получения данных пользователей компаний', 'getAllUsers');
    }
    return [];
};

export const getAllProjects = async (token) => {
    try {
        const companies = await getAllCompanies(token);
        if (companies.length > 0) {
            const requests = companies.map(async (company) => {
                const company_id = company.id;
                const response = await axios.get(`${api}/company/${company_id}/projects/projects/`, headersRequests('get', token));
                // console.log('\n response.data', response.data.data);
                return response?.data.data.map(project => ({ ...project, company_id })) || [];
                    // ?.users?.map(user => ({ ...user, company_id })) || [];
            });

            const allProjects = (await Promise.all(requests)).flat();
            // console.log('\n  allProjects', allProjects);
            const projects = allProjects
                .map(project => ({
                    ...project,
                    company_name: companies.find(company => company.id === project.company_id).name,
                    action_label: project.action === 1 ? "Открыт" : "Эаблокирован"
                }))
                .sort((a, b) => a.id - b.id)
            ;
            // console.log('\n projects', projects);

            return ({
                projects,
                companies
            });
        }
    }
    catch (errors) {
        ErrorOccurred(errors.response, 'Ошибка получения данных проектов компаний', 'getAllProjects');
    }
    return [];
};

export const getCompanyTenders = async (token, company_id, project_id) => {
    try {

        const getUser = await UserCheck(token);
        if (getUser && Object.keys(getUser).length > 0) {

            const response = await axios.get(`${api}/tenders/companies/${company_id}/`, headersRequests('get', token));
            const tenders = response?.data?.filter(tender => tender.project.id === project_id);
            // console.log('\n tenders', tenders);
            return tenders;
        }
    }
    catch (errors) {
        ErrorOccurred(errors.response, 'Ошибка получения данных тендеров компаний', 'getCompanyTenders');
    }
    return [];
};

export const getSubcomanies = async (token, company_id) => {
    try {

        const getUser = await UserCheck(token);
        if (getUser && Object.keys(getUser).length > 0) {

            const response = await axios.get(`${api}/company/${company_id}/subcompanies`, headersRequests('get', token));
            // console.log('\n response', response.data?);
            return response?.data?.data;
        }
    }
    catch (errors) {
        ErrorOccurred(errors.response, 'Ошибка получения данных тендеров компаний', 'getCompanyTenders');
    }
    return [];
};

export const getBuildings = async (token, company_id, project_id) => {
    try {

        // console.log('\n getBuildings', token, company_id, project_id);

        const buildings = await axios.get(
            `${api}/tep-building/companies/${company_id}/projects/${project_id}/buildings/`,
            headersRequests('get', token)
        );
        // console.log('\n buildings', buildings.data.data);
        return buildings?.data?.data;
    }
    catch (errors) {
        ErrorOccurred(errors.response, 'Ошибка получения данных по корпусам', 'getBuildings');
    }
    return [];
};

export const getSections = async (token, company_id, project_id) => {
    try {

        // console.log('\n getSections', token, company_id, project_id);

        const buildings = await getBuildings(token, company_id, project_id);
        // console.log('\n buildings', buildings);
        const sections = buildings.map(async (building) => {

            const response = await axios.get(
                `${api}/plans-configurator/companies/${company_id}/projects/${project_id}/building/${building.id}/sections/`,
                headersRequests('get', token)
            );

            // console.log('\n sectionList', sectionList);
            return response.data?.map(s => ({
                ...s,
                company_id,
                project_id,
            }));
        });
        const allSections = await Promise.all(sections);
        // console.log('\n allSections', allSections);
        return allSections;
    }
    catch (errors) {
        ErrorOccurred(errors.response, 'Ошибка получения данных по секциям', 'getSections');
    }
    return [];
};

export const getTariffs = async (token) => {

    try {
        const tariffs = await axios.get(
            `${api}/tariffs/`,
            headersRequests('get', token)
        );
        const allTariffs = tariffs?.data?.data?.map(tariff => {
            
            // console.log('\n tariff', tariff);
            
            return (tariff);
        })
        // console.log('\n getTariffs', allTariffs);
        return (allTariffs);
    }
    catch (errors) {
        ErrorOccurred(errors.response, 'Ошибка получения данных тарифов', 'getTariffs');
    }
    return [];
}

export const getCompany = async (token, company_id) => {
    try {
        const getUser = await UserCheck(token);
        if (getUser && Object.keys(getUser).length > 0) {
            const response = await axios.get(`${api}/company/${company_id}/`, headersRequests('get', token));

            // console.log('\n response', response.data);
            return (response.data);
        }
    }
    catch (errors) {
        ErrorOccurred(errors.response, 'Ошибка получения данных компании', 'getCompany');
    }
    return [];
}