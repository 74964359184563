import React from "react";
import {Link} from "react-router-dom";
import {Box, Button} from "@mui/material";
import FilePresentOutlinedIcon from "@mui/icons-material/FilePresentOutlined";
import Typography from "@mui/material/Typography";
import CreditCardOffRoundedIcon from '@mui/icons-material/CreditCardOffRounded';
import CreditScoreRoundedIcon from '@mui/icons-material/CreditScoreRounded';
import CurrencyRubleRoundedIcon from '@mui/icons-material/CurrencyRubleRounded';
import Person2RoundedIcon from '@mui/icons-material/Person2Rounded';
import PeopleRoundedIcon from '@mui/icons-material/PeopleRounded';
import ApartmentRoundedIcon from '@mui/icons-material/ApartmentRounded';

export const companyScheme = [
    {
        "field": "id",
        "headerName": "ID",
        "headerAlign": "center",
        "type": "number",
        "width": 10,
    },
    {
        "field": "name",
        "headerName": "Компания",
        "headerAlign": "center",
        "cellClassName": "name-column--cell",
        "flex": 1,
    },
    {
        "field": "email",
        "headerName": "Email",
        "headerAlign": "center",
        "flex": 1,
    },
    {
        "field": "inn",
        "headerName": "ИНН",
        "headerAlign": "center",
    },
    {
        "field": "subcompany",
        "headerName": "Тип",
        "type": "boolean",
        "headerAlign": "center",
        "renderCell": (params) => {
            return (
                <Typography
                    sx={{
                        color: params.row.subcompany ? "" : "success.main",
                    }}
                >
                    {params.row.subcompany_label}
                </Typography>
            );
        }
    },
    {
        "field": "is_paid",
        "headerName": "Оплата",
        "headerAlign": "center",
        "type": "boolean",
        "flex": 0.5,
        "renderCell": (params) => {

            return (!params.row.subcompany &&
                <Typography
                    color={!params.row.is_paid ? "error" : "secondary"}
                >
                    {!params.row.is_paid ?
                        <CreditCardOffRoundedIcon
                            sx={{height: 13}}
                        /> :
                        <CreditScoreRoundedIcon
                            sx={{height: 13}}
                        />
                    }
                    {params.row.is_paid_status}
                </Typography>
            );
        }
    },
    {
        "field": "state_account",
        "headerName": "Состояние счета",
        "headerAlign": "center",
        "type": "number",
        "flex": 0.5,
        "renderCell": (params) => {

            // if (!params.row.subcompany) console.log('\n params.row.is_paid_status', +params.row.state_account >= 0);

            return (!params.row.subcompany &&
                <Typography
                    sx={{
                        color: params.row.state_account >= 0? "success.main" : "error.main",
                    }}
                >
                    {params.row.state_account}
                    <CurrencyRubleRoundedIcon
                        sx={{
                            height: 10,
                        }}
                    />
                </Typography>
            );
        }
    },
    {
        "field": "type",
        "headerName": "Лицо",
        "headerAlign": "center",
        "type": "number",
        "flex": 0.5,
        "align": "center",
        "renderCell": (params) => {
            return (
                <Button
                    variant="contained"
                    startIcon={params.row.type === 1 ?
                        <Person2RoundedIcon /> :
                        params.row.type === 2 ?
                            <ApartmentRoundedIcon /> :
                            <PeopleRoundedIcon />
                    }
                    component={Link}
                    to={`${process.env.PUBLIC_URL}/edit-company/?company_id=${params.id}`}
                >
                    {params.row.type_label}
                </Button>
            );
        }
    }
];

export const usersScheme = [
    {
        "field": "id",
        "headerName": "ID",
        "flex": 0.1
    },
    {
        "field": "company_name",
        "headerName": "Компания",
        "flex": 2
    },
    {
        "field": "email",
        "headerName": "Email",
        "cellClassName": "name-column--cell",
        "flex": 2
    },
    {
        "field": "full_name",
        "headerName": "Полные инициалы",
        "flex": 2
    },
    {
        "field": "short_name",
        "headerName": "Краткие инициалы",
        "flex": 2
    },
    {
        "field": "is_active",
        "headerName": "Доступ",
        "flex": 1
    },
    {
        "field": "is_blocked",
        "headerName": "Заблокирован",
        "flex": 1
    },
    {
        "field": "is_deleted",
        "headerName": "Уволен",
        "flex": 1
    },
    {
        "field": "projects",
        "headerName": "Проекты",
        "flex": 1
    },
    {
        "field": "role",
        "headerName": "Роли/Должности",
        "flex": 1
    },
    {
        "field": "updated_at",
        "headerName": "Дата изменения",
        "flex": 1
    }
];

export const projectsScheme = [
    {
        "field": "id",
        "headerName": "ID",
        "flex": 0.5
    },
    {
        "field": "company_id",
        "headerName": "ID Компании",
        "flex": 0.5
    },
    {
        "field": "company_name",
        "headerName": "Компания",
        "flex": 2
    },
    {
        "field": "name",
        "headerName": "Наименование",
        "cellClassName": "name-column--cell",
        "flex": 2
    },
    {
        "field": "action_label",
        "headerName": "Доступ",
        "flex": 2
    }
];

export const ordersScheme = [
    {
        "field": "id",
        "headerName": "ID",
        "type": "number",
        "cellClassName": "name-column--cell",
        "flex": 0.1
    },
    {
        "field": "contract",
        "headerName": "№ Договора",
        "cellClassName": "name-column--cell",
        "flex": 1.5
    },
    {
        "field": "history_steps",
        "headerName": "История количество изменений",
        "type": "number",
        "flex": 0.3,
        "headerAlign": "left",
        "align": "center"
    },
    {
        "field": "status",
        "headerName": "История текущий статус",
        "headerAlign": "center",
        "flex": 1.5
    },
    {
        "field": "company_id",
        "headerName": "ID компании",
        "type": "number",
        "flex": 0.3,
        "headerAlign": "left",
        "align": "center"
    },
    {
        "field": "project_id",
        "headerName": "ID проекта",
        "type": "number",
        "flex": 0.3,
        "headerAlign": "left",
        "align": "center"
    },
    {
        "field": "tender_id",
        "headerName": "ID тендера",
        "type": "number",
        "flex": 0.3,
        "headerAlign": "left",
        "align": "center"
    },
    {
        "field": "building_id",
        "headerName": "ID Корпуса",
        "type": "number",
        "flex": 0.3,
        "headerAlign": "left",
        "align": "center"
    },
    {
        "field": "section_id",
        "headerName": "ID секции",
        "type": "number",
        "flex": 0.3,
        "headerAlign": "left",
        "align": "center"
    },
    {
        "field": "category",
        "headerName": "Категория",
        "type": "number",
        "align": "center",
        "cellClassName": "name-column--cell"
    },
    {
        "field": "date_completion",
        "headerName": "Дата устранения",
        "type": "date"
    }
];

export const subcompaniesDocumentsScheme = [
    {
        "field": "tender_name",
        "headerName": "Тендер",
        "align": "left",
        "headerAlign": "center",
        "flex": 1
    },
    {
        "field": "tender_company_name",
        "headerName": "Компания",
        "align": "left",
        "headerAlign": "center",
        "flex": 1
    },
    {
        "field": "tender_subcompany_name",
        "headerName": "Подрядчик",
        "align": "left",
        "headerAlign": "center",
        "flex": 1
    },
    {
        "field": "register_documents_length",
        "headerName": "Документы при регистрации",
        "flex": 1,
        "headerAlign": "center",
        "renderCell": (params) => {
            return (
                params.row.register_documents_length > 0 &&
                <Button
                    variant="contained"
                    startIcon={<FilePresentOutlinedIcon />}
                    color="warning"
                    component={Link}
                    to={`${process.env.PUBLIC_URL}/subcompanies-documents/edit-documents/?type=1&tender=${params.row.id}`}
                >
                    Редактировать ({params.row.register_documents_length})
                </Button>
            );
        },
    },
    {
        "field": "register_acts_length",
        "headerName": "Акты передачи строительного объекта",
        "flex": 1,
        "headerAlign": "center",
        "renderCell": (params) => {
            return (
                params.row.register_acts_length > 0 &&
                <Button
                    variant="contained"
                    startIcon={<FilePresentOutlinedIcon />}
                    color="warning"
                    component={Link}
                    to={`${process.env.PUBLIC_URL}/subcompanies-documents/edit-documents/?type=2&tender=${params.row.id}`}
                >
                    Редактировать ({params.row.register_acts_length})
                </Button>
            );
        },
    },
    {
        "field": "quality_documents_length",
        "headerName": "Документы о качестве выполненных работ",
        "flex": 1,
        "headerAlign": "center",
        "renderCell": (params) => {
            return (
                params.row.quality_documents_length > 0 &&
                <Button
                    variant="contained"
                    startIcon={<FilePresentOutlinedIcon />}
                    color="warning"
                    component={Link}
                    to={`${process.env.PUBLIC_URL}/subcompanies-documents/edit-documents/?type=3&tender=${params.row.id}`}
                >
                    Редактировать ({params.row.quality_documents_length})
                </Button>
            );
        },
    },
    {
        "field": "work_payment_certificates_length",
        "headerName": "Акты выполненных работ",
        "flex": 1,
        "headerAlign": "center",
        "renderCell": (params) => {
            return (
                params.row.work_payment_certificates_length > 0 &&
                <Button
                    variant="contained"
                    startIcon={<FilePresentOutlinedIcon />}
                    color="warning"
                    component={Link}
                    to={`${process.env.PUBLIC_URL}/subcompanies-documents/edit-documents/?type=4&tender=${params.row.id}`}
                >
                    Редактировать ({params.row.work_payment_certificates_length})
                </Button>
            );
        },
    },
];

export const tariffsScheme = [
    {
        "field": "id",
        "headerName": "ID",
        "align": "center",
        "headerAlign": "center",
    },
    {
        "field": "name",
        "headerName": "Наименование",
        "cellClassName": "name-column--cell",
        "align": "left",
        "headerAlign": "center",
    },
    {
        "field": "administrator",
        "headerName": "Администратор",
        "headerAlign": "center",
        "renderCell": (params) => {
            return (
                <Box>
                    <Typography>
                        {params.row.administrator}
                    </Typography>
                    <Typography>
                        {params.row.administrator_price}
                    </Typography>
                </Box>
            );
        },
    },
    {
        "field": "coordinator",
        "headerName": "Координатор",
        "headerAlign": "center",
        "renderCell": (params) => {
            return (
                <Box flex>
                    <Typography>
                        {params.row.coordinator}
                    </Typography>
                    <Typography>
                        {params.row.coordinator_price}
                    </Typography>
                </Box>
            );
        },
    },
    {
        "field": "manager",
        "headerName": "Начальник участка",
        "headerAlign": "center",
        "renderCell": (params) => {
            return (
                <Box flex>
                    <Typography>
                        {params.row.manager}
                    </Typography>
                    <Typography>
                        {params.row.manager_price}
                    </Typography>
                </Box>
            );
        },
    },
    {
        "field": "build_control",
        "headerName": "Стройконтроль",
        "headerAlign": "center",
        "renderCell": (params) => {
            return (
                <Box flex>
                    <Typography>
                        {params.row.build_control}
                    </Typography>
                    <Typography>
                        {params.row.build_control_price}
                    </Typography>
                </Box>
            );
        },
    },
    {
        "field": "gip",
        "headerName": "ГИП",
        "headerAlign": "center",
        "renderCell": (params) => {
            return (
                <Box flex>
                    <Typography>
                        {params.row.gip}
                    </Typography>
                    <Typography>
                        {params.row.gip_price}
                    </Typography>
                </Box>
            );
        },
    },
    {
        "field": "contract",
        "headerName": "Подрядчик (Лот)",
        "headerAlign": "center",
        "renderCell": (params) => {
            return (
                <Box flex>
                    <Typography>
                        {params.row.contract}
                    </Typography>
                    <Typography>
                        {params.row.contract_price}
                    </Typography>
                </Box>
            );
        },
    },
];