import {ErrorOccurred} from "./ErrorOccurred";
import axios from "axios";
import {api, headersRequests} from "./Settings";

export const resetEstimatePeriod = async (token, tender) => {

    try {

        const company_id = tender?.tender?.project.company.id;
        const project_id = tender?.tender?.project.id;
        const tender_id = tender?.id;
        const subcompany_id = tender?.subcompany;

        console.log('\n resetEstimatePeriod', tender);

        const reset_financial_plan_by_super_admin = await axios.post(
            `${api}/work-journal/companies/${company_id}/projects/${project_id}/tenders/${tender_id}/subcompanies/${subcompany_id}/reset-financial-plan-by-super-admin/periods/`,
            '',
            headersRequests('post', token)
        );

        // console.log('\n reset_financial_plan_by_super_admin', reset_financial_plan_by_super_admin.request.status === 200);

        return (reset_financial_plan_by_super_admin.request.status === 200);
    }
    catch (errors) {
        ErrorOccurred(errors.response, 'Ошибка изменения финансового периода', 'resetEstimatePeriod');
    }
    return [];
};