import React, {useEffect, useState} from "react";
import Form from "../UI/Form/index"
import {GetUser, UserCheck} from "../methods/getUser";
import { useCookies } from "react-cookie";
import {useNavigate} from "react-router-dom";
import {Box} from "@mui/material";

export default function Authorization({setTokenAuth}) {

    const navigate = useNavigate();

    const [isAuth, setIsAuth] = useState(null);
    const [cookie, setCookie] = useCookies(['access']);
    const [token, setToken] = useState(Object.keys(cookie).length > 0 && cookie?.access);
    const [errors, setErrors] = useState(null);

    // if (Object.keys(cookie).length > 0) console.log('\n cookie', cookie);
    // if (isAuth) console.log('\n isAuth', isAuth);

    const fields = [
        {
            label: "Email",
            name: "email",
            type: "email",
        },
        {
            label: "Пароль",
            name: "password",
            type: "password",
        },
    ];
    const getAccess = async (data) => {
        // console.log('\n getAccess', data);

        const apiToken = await GetUser(data);
        // console.log('\n token', token.access);

        if (apiToken?.errors) {
            setErrors(token);
        } else {
            setErrors(null);
            if (apiToken?.access) setToken(`${apiToken?.access}`);
        }
    }

    useEffect(() => {

        if (token) {

            const checkAccess = async () => {
                // console.log('\n token', api, token);

                const getUser = await UserCheck(token);
                // console.log('\n getUser', getUser);

                if (getUser?.errors) {
                    setErrors(getUser);
                    setIsAuth(null);
                } else {
                    setErrors(null);
                    if (getUser?.is_superuser) {
                        setIsAuth(getUser);
                    }
                    // else window.location.replace('/');
                }
            }

            checkAccess();
        }
    }, [token]);
    useEffect(() => {

        if (token && isAuth) {

            setCookie(
                "access",
                `${token}`,
                {
                    sameSite: "Lax",
                    secure: true,
                    maxAge: 16 * 3600,
                }
            );

            setTokenAuth(token);

            navigate(`${process.env.PUBLIC_URL}/welcome`);
        }
    },[token, isAuth, navigate, setCookie, setTokenAuth]);

    return (
        <Box
            sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100vh",
                flexGrow: 1,
                flexDirection: "column"
            }}
        >
            {!isAuth &&
                <Form
                    title={!isAuth && "Авторизуйтесь"}
                    subtitle={

                        errors ?
                            errors.jsx :
                            "для продолжения работы необходимо ввести данные учетной записи"
                    }
                    errors={errors}
                    correct={isAuth}
                    fields={fields}
                    submitButton={`Авторизоваться`}
                    onSubmit={getAccess}
                />
            }
        </Box>
    );
}