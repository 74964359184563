import {Box, Button, Stack} from "@mui/material";
import * as React from 'react';
import CheckIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import Header from "../../Header";

const PermissionRequest = ({
                               label,
                               subtitle,
                               permission,
                           }) => {

    return (
        <Box m="20px">
            <Header title={label} subtitle={subtitle} />
            <Stack direction="row" spacing={2}>
                <Button
                    variant="contained"
                    startIcon={<CheckIcon />}
                    color="warning"
                    onClick={() => permission(true)}
                >
                    Подтвердить
                </Button>
                <Button
                    variant="contained"
                    startIcon={<CancelIcon />}
                    color="success"
                    onClick={() => permission(false)}
                >
                    Отменить
                </Button>
            </Stack>
        </Box>
    );
}

export default PermissionRequest;