import React, {useEffect, useState} from "react";
import {getAllCompanies} from "../../methods/getCompanies";
import DashTable from "../../UI/Tables/DashTable";
import {companyScheme} from "../../UI/Tables/dash_scheme";

export default function Companies({ token }) {

    const [companies, setCompanies] = useState(null);

    useEffect(() => {

        const getData = async () => {

            const updateGetCompanies = await getAllCompanies(token);
            const updateCompanies = updateGetCompanies
                .sort((a, b) => a.id - b.id)
            ;
            // console.log('\n updateCompanies', updateCompanies);
            setCompanies(updateCompanies);
        }

        getData();
    }, [token]);

    return (companies && <DashTable
        data={companies}
        label={"Список компаний"}
        columns={companyScheme}
    />);
}