export const api = "https://erp-system-api.sixhands.co/api/v1";

export const fileServer = "https://erp-system.sixhands.co";

export const currentPath = process.env.PUBLIC_URL;

// Define the headers configuration based on request type and token
export const headersRequests = (type, token) => {
    const types = {
        get: {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        },
        post: {
            headers: {
                'accept': '*/*',
                'Authorization': `Bearer ${token}`
            }
        },
        post_form: {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${token}`
            }
        },
        post_json: {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        },
        post_auth: {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }
    };

    return !token ? types["post_auth"] : types[type];
};