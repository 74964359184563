import {useEffect, useState} from "react";
import {getTariffs} from "../../methods/getCompanies";

export default function EditTariffs ({token}) {

    const [currentTariffs, setCurrentTariffs] = useState(null);

    useEffect(() => {

        const getData = async () => {

            const updateTariffs = await getTariffs(token);
            setCurrentTariffs(updateTariffs);
        }

        getData();
    },[token]);

    if (currentTariffs) console.log('\n currentTariffs', currentTariffs);

    return (
        <div>
            Edit Tariffs
        </div>
    )
}