import { Box, Button, useTheme } from "@mui/material";
import Header from "../../Header";
import { tokens } from "../../../theme";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import CalendarMonth from "@mui/icons-material/CalendarMonth";
import * as React from "react";

const SelectSubcompany = ({ tenders, setSelectTender }) => {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const columns = [
        { field: "id", headerName: "ID" },
        {
            field: "name",
            headerName: "Тендер",
            flex: 1,
            cellClassName: "name-column--cell",
        },
        {
            field: "is_won",
            headerName: "Тендер выигран",
            type: "boolean",
        },
        {
            field: "subcompany_name",
            headerName: "Подрядчик",
            flex: 1,
        },
        {
            field: "subcompany",
            headerName: "ID Подрядчика",
            type: "number",
        },
        {
            field: "project_name",
            headerName: "Проект",
            flex: 1,
        },
        {
            field: "project_id",
            headerName: "ID Проекта",
            type: "number",
        },
        {
            field: "company_name",
            headerName: "Компиния",
            flex: 1,
        },
        {
            field: "company_id",
            headerName: "ID Компинии",
            type: "number",
        },
        {
            field: "reset",
            headerName: "Сбросить период",
            flex: 1,
            renderCell: (params) => {
                return (
                    <Button
                        variant="contained"
                        startIcon={<CalendarMonth />}
                        color="warning"
                        onClick={() => setSelectTender(params.row.id)}
                    >
                        Сбросить
                    </Button>
                );
            },
        },
    ];

    return (
        <Box
            m="20px"
            display="flex"
            flexDirection="column"
        >
            <Header
                title="Сброс финансового периода сметы"
                subtitle="Выбирите подрядчика"
                flex="none"
            />
            <Box
                m="40px 0 0 0"
                height="75vh"
                sx={{
                    "& .MuiDataGrid-root": {
                        border: "none",
                    },
                    "& .MuiDataGrid-cell": {
                        borderBottom: "none",
                    },
                    "& .name-column--cell": {
                        color: colors.greenAccent[300],
                    },
                    "& .MuiDataGrid-columnHeaders": {
                        backgroundColor: colors.blueAccent[700],
                        borderBottom: "none",
                    },
                    "& .MuiDataGrid-virtualScroller": {
                        backgroundColor: colors.primary[400],
                    },
                    "& .MuiDataGrid-footerContainer": {
                        borderTop: "none",
                        backgroundColor: colors.blueAccent[700],
                    },
                    "& .MuiCheckbox-root": {
                        color: `${colors.greenAccent[200]} !important`,
                    },
                    "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                        color: `${colors.grey[100]} !important`,
                    },
                }}
            >
                <DataGrid
                    rows={tenders}
                    columns={columns}
                    components={{ Toolbar: GridToolbar }}
                />
            </Box>
        </Box>
    );
};

export default SelectSubcompany;
