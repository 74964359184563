import React, {useEffect, useState} from "react";
import {getAllDocuments} from "../../methods/subcompaniesMethods";
import DashTable from "../../UI/Tables/DashTable";
import {subcompaniesDocumentsScheme} from "../../UI/Tables/dash_scheme";

export default function SubcompaniesDocuments({token}) {

    const [subcompanies, setSubcompanies] = useState(null);

    useEffect(() => {

        const getDocumentsHandler = async () => {

            const updateDocuments = await getAllDocuments(token);
            if (updateDocuments) {
                // console.log('\n updateDocuments', updateDocuments);
                setSubcompanies(updateDocuments);
            }
        }

        getDocumentsHandler();
    },[token]);

    console.log('\n subcompanies', subcompanies);

    return(
        subcompanies && <DashTable
            data={subcompanies}
            label={"Список компаний подрядчиков"}
            columns={subcompaniesDocumentsScheme}
        />
    );
}