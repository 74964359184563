import { Typography, Box, useTheme } from "@mui/material";
import { tokens } from "../theme";

const Header = (
    {
        title,
        subtitle,
        correct,
        error,
        warning,
    }
) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <Box mb="30px">
      <Typography
        variant="h2"
        color={colors.grey[100]}
        fontWeight="semi-bold"
        sx={{ m: "0 0 5px 0" }}
      >
        {title}
      </Typography>
      <Typography variant="h5" color={correct ?
          colors.greenAccent[400] :
              error ? colors.redAccent[400] :
              warning ? colors.warning[100] :
          colors.primary[200]
      }>
        {subtitle}
      </Typography>
    </Box>
  );
};

export default Header;
