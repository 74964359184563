import {Box, Button, List, ListItem, ListItemText, Stack, Typography} from "@mui/material";
import * as React from 'react';
import Header from "../../Header";
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import CheckIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';

const MessageJSX = (
    {
        label,
        subtitle,
        message,
        correct,
        error,
        button,
        answer,
    }
) => {

    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#141b2d' : correct ? 'rgba(53,144,120,0.2)' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        width: 'max-content',
        maxWidth: '80%',
        border: correct ? '1px solid #4cceac' : '1px solid #ff000'
    }));


    return (
        <Box m="20px">
            <Header title={label} subtitle={subtitle} />
            <Item
                sx={{
                    my: 1,
                    mx: 'auto',
                    p: 2,
                    backgroundColor: correct ? 'rgba(53,144,120,0.2)' : error ? 'rgba(200,50,50,0.3)' : '',
                }}
            >
                <Stack
                    spacing={2}
                    direction="row"
                    alignItems="center"
                >
                    {correct && <CheckIcon
                        color={"secondary"}
                    />}
                    {error && <ErrorIcon
                        color={"error"}
                    />}
                    {error ?
                        <List>
                            {answer && <ListItem key={"id"}>
                                <ListItemText
                                    primary={answer}
                                />
                            </ListItem>}
                            {message}
                        </List> :
                        <Typography noWrap>{message}</Typography>
                    }
                </Stack>
                {button &&
                    <Button
                        onClick={button.onClick}
                        color={correct ? "secondary" : "primary"}
                        variant="contained"
                    >
                        {button.label}
                    </Button>
                }
            </Item>
        </Box>
    );
}

export default MessageJSX;